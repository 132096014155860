export const usernameProps = {
    variant: "standard",
    label: "felhasználónév",
    name: "username",
};
export const passwordProps = {
    variant: "standard",
    label: "jelszó",
    name: "password",
};
export const rePasswordProps = {
    variant: "standard",
    label: "jelszó mégegyszer",
    name: "rePassword",
};
export const submitBtnProps = {
    type: "submit",
};
