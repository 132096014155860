import React, { useState } from "react";
import "./sidebar.css";
import Navbar from "../../components/navbar/Navbar";
import Profile from "../../components/profile/Profile";
import ToggleBtn from "../toggleBtn/ToggleBtn";

const Sidebar = () => {
  const [toggleBtn, setToggleBtn] = useState(false);

  return (
    <>
      <ToggleBtn toggleBtn={toggleBtn} setToggleBtn={setToggleBtn} />
      <section id="sidebar">
        <div className="d-flex flex-column">
          <Profile />
          <Navbar setToggleBtn={setToggleBtn} />
        </div>
      </section>
    </>
  );
};

export default Sidebar;
