import DataService from "../../../services/login.service";
import actions from "../actions/action";

const messageTimer = 3000;

export const loadReadData = () => (dispatch) => {
  DataService()
    .readData()
    .then((res) => {
      dispatch(actions.readData(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loadReadSingleData = (id) => (dispatch) => {
  DataService()
    .readSingleData(id)
    .then((res) => {
      dispatch(actions.readSingleData(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loadCreateData = (data) => (dispatch) => {
  DataService()
    .createData(data)
    .then((res) => {
      const error = res.data.error;
      const error_msg = res.data.error_msg;
      const success_msg = res.data.success_msg;

      if (error) {
        dispatch(actions.error(error));
      } else {
        if (error_msg) {
          dispatch(actions.message(res.data));
        } else if (success_msg) {
          const adminUser = res.data.adminUser;

          if (adminUser) {
            const adminID = res.data.adminUser.id;
            const adminTOKEN = res.data.adminUser.token;
            localStorage.setItem("adminID", adminID);
            localStorage.setItem("adminTOKEN", adminTOKEN);
            dispatch(actions.message(res.data));
            setTimeout(() => {
              dispatch(actions.message({}));
              dispatch(actions.error([]));
              window.location.replace("/#/admin");
            }, messageTimer);
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
