import React from "react";
import "./home.css";
import About from "../../components/about/About";
import Contact from "../../components/contact/Contact";
import Facts from "../../components/facts/Facts";
import Hero from "../../components/hero/Hero";
import Portfolio from "../../components/portfolio/Portfolio";
import Resume from "../../components/resume/Resume";
import Services from "../../components/services/Services";
import Skills from "../../components/skills/Skills";
import Testimonials from "../../components/testimonials/Testimonials";
import { metaTitle } from "../../config/baseOptions";
import { MetaTitle } from "../../config/globalFunctions";
import Languages from "../../components/languages/Languages";

const Home = () => {
  MetaTitle(metaTitle.home);

  return (
    <>
      <Languages />
      <Hero />
      <main id="main">
        <About />
        {/* <Facts /> */}
        <Skills />
        <Resume />
        <Portfolio />
        {/* <Services /> */}
        {/* <Testimonials /> */}
        {/* <Contact /> */}
      </main>
    </>
  );
};

export default Home;
