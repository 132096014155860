import { Paper, styled } from "@mui/material";

export const Colors = {
  green: "#007a4d",
  blue: "#071D3B",
};

export const Body = styled(Paper)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
}));

export const Main = styled(Paper)(() => ({
  flexGrow: 1,
  boxShadow: "none",
}));
