import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField, Button, Alert, Stack } from "@mui/material";
import {
  usernameProps,
  passwordProps,
  rePasswordProps,
  submitBtnProps,
} from "./properties";
import { loadCreateData } from "../../redux/sections/adminRegister.section/reducers/thunks";
import { loadReadData } from "../../redux/sections/login.section/reducers/thunks";

export default function AdminRegister() {
  const dispatch = useDispatch();
  const { error, message } = useSelector((state) => state.adminRegister);

  const [data, setData] = useState({
    username: "",
    password: "",
    rePassword: "",
  });
  const { username, password, rePassword } = data;
  const [errorMsg, setErrorMsg] = useState([]);
  const [resMessage, setResMessage] = useState({});

  useEffect(() => {
    dispatch(loadReadData());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrorMsg(error);
  }, [error]);

  useEffect(() => {
    setResMessage(message);
  }, [message]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const submitBtn = (e) => {
    e.preventDefault();

    dispatch(loadCreateData(data));
    setData({
      username: "",
      password: "",
      rePassword: "",
    });
  };

  return (
    <Box sx={{ background: "gainsboro", height: "100%" }} component="div">
      <div className="container">
        <div className="row">
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: "100px 30px 30px",
              position: "relative",
            }}
            noValidate
            autoComplete="off"
            method="POST"
            onSubmit={submitBtn}
          >
            <h3 className="mb-4">Admin reg</h3>
            <Stack sx={{ position: "absolute", top: 15 }}>
              {resMessage && resMessage.error_msg && (
                <Alert severity="error">{resMessage.error_msg}</Alert>
              )}
              {resMessage && resMessage.success_msg && (
                <Alert severity="success">{resMessage.success_msg}</Alert>
              )}
            </Stack>
            <TextField
              value={username}
              onChange={handleChange}
              {...usernameProps}
            />
            <TextField
              value={password}
              onChange={handleChange}
              {...passwordProps}
            />
            <TextField
              value={rePassword}
              onChange={handleChange}
              {...rePasswordProps}
            />

            <Button className="mt-4" {...submitBtnProps}>
              Regisztrálok
            </Button>

            <Stack sx={{ mt: 5 }}>
              {errorMsg &&
                errorMsg.map((err, key) => (
                  <Alert key={key} severity="error">
                    {err.msg}
                  </Alert>
                ))}
            </Stack>
          </Box>
        </div>
      </div>
    </Box>
  );
}
