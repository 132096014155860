import React from "react";
import "./profile.css";
import { ExternalLink } from "react-external-link";
import { useTranslation } from "react-i18next";
import { socials } from "../../config/socials";

const Profile = () => {
  const { t } = useTranslation("hero");

  return (
    <div className="profile">
      <div className="profile_img_frame">
        <img src="/assets/img/profile-img.png" alt="" className="img-fluid" />
      </div>
      <h3 className="text-light text-center">{t("hero_name")}</h3>
      <div className="social-links mt-3 text-center">
        {socials.map(({ href, icon }, index) => (
          <ExternalLink key={index} href={href}>
            <i className={icon}></i>
          </ExternalLink>
        ))}
        {/* <ExternalLink href="https://www.instagram.com">
          <i className="bx bxl-instagram"></i>
        </ExternalLink> */}
      </div>
    </div>
  );
};

export default Profile;
