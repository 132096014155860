import DataService from "../../../services/adminRegister.service";
import actions from "../actions/action";

const messageTimer = 2800;

export const loadCreateData = (data) => (dispatch) => {
  DataService()
    .createData(data)
    .then((res) => {
      const error = res.data.error;
      const error_msg = res.data.error_msg;
      const success_msg = res.data.success_msg;

      if (error) {
        dispatch(actions.error(error));
      } else {
        if (error_msg) {
          dispatch(actions.message(res.data));
        } else if (success_msg) {
          dispatch(actions.message(res.data));
        }
        setTimeout(() => {
          dispatch(actions.message({}));
          dispatch(actions.error([]));
          window.location.replace("/");
        }, messageTimer);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loadUpdateData = (id, data) => (dispatch) => {
  DataService()
    .updateData(id, data)
    .then((res) => {
      const error = res.data.error;
      const error_msg = res.data.error_msg;
      const success_msg = res.data.success_msg;

      if (error) {
        dispatch(actions.error(error));
      } else {
        if (error_msg) {
          dispatch(actions.message(res.data));
        } else if (success_msg) {
          dispatch(actions.message(res.data));
        }
        setTimeout(() => {
          dispatch(actions.message({}));
          dispatch(actions.error([]));
          window.location.reload();
        }, messageTimer);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
