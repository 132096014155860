import React from "react";
import ReactCardFlip from "react-card-flip";
import { ExternalLink } from "react-external-link";
import { useTranslation } from "react-i18next";
import "./portfolio.css";
import { InfoOutlinedIcon, ProjectList } from "./portfolio.styles";

const CardFlip = ({ data, id, setProjectId }) => {
  const { t } = useTranslation("portfolio");
  
  const projects = t("portfolio_projects_back", {
    returnObjects: true,
  });

  if (projects[id] === undefined) return;

  const handleClick = () => {
    const elementFront = document.querySelectorAll(".react-card-front");
    const elementBack = document.querySelectorAll(".react-card-back");

    for (let i = 0; i < elementFront.length; i++) {
      const e = elementFront[i];

      console.log("react-card-flip");
      

      if (i === id) {
        e.classList.remove("removeFlip");
        e.classList.add("addFlipFront");
      } else {
        e.classList.remove("addFlipFront");
        e.classList.add("removeFlip");
      }
    }

    for (let i = 0; i < elementBack.length; i++) {
      const e = elementBack[i];

      if (i === id) {
        e.classList.remove("addFlipBack");
        e.classList.add("removeFlip");
      } else {
        e.classList.remove("removeFlip");
        e.classList.add("addFlipBack");
      }
    }
  };

  const projectIdFunc = (key) => {
    setProjectId(key);
  };

  return (
    <ReactCardFlip key={id} flipDirection="horizontal">
      <div className="CardFront">
        <div className="portfolio-item filter-app m-0">
          <div className="portfolio-wrap">
            <img
              style={data.class}
              src={data.img}
              className="img-fluid"
              alt=""
            />
            <div className="portfolio-links">
              <ExternalLink
                href={data.link}
                title={data.title}
                className="portfolio-link"
              >
                <i className="bx bx-link"></i>
              </ExternalLink>
              <button onClick={handleClick}>info</button>
            </div>
          </div>
        </div>
      </div>
      <div className="CardBack">
        <div className="portfolio-item filter-app m-0">
          <div className="portfolio-wrap card__back">
            <ProjectList>
              <li>
                <span className="position-relative">
                  {projects[id]?.[0]}

                  {projects[id]?.[4] && (
                    <InfoOutlinedIcon
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() => projectIdFunc(id)}
                    />
                  )}
                </span>
              </li>
              {projects[id]?.map(
                (item, index) =>
                  index > 0 && index < 4 && <li key={index}>{item}</li>
              )}
            </ProjectList>
          </div>
        </div>
      </div>
    </ReactCardFlip>
  );
};

export default CardFlip;
