import React from "react";
import { Body, Main } from "../config/css/globalStyle";
import Footer from "../components/footer/Footer";
import Sidebar from "../components/sidebar/Sidebar";

const FrontLayout = (props) => {
  return (
    <Body id="front_layout">
      <Sidebar />
      <Main id="home_page">{props.children}</Main>
      <Footer />
    </Body>
  );
};

export default FrontLayout;
