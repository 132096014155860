import * as React from "react";
import "./languages.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import i18n from "i18next";
import { Typography } from "@mui/material";

const Languages = () => {
  const languages = [
    {
      code: "de",
      name: "Deutsch",
      country_code: "de",
    },
    {
      code: "hu",
      name: "Magyar",
      country_code: "hu",
    },
    // {
    //   code: "en",
    //   name: "English",
    //   country_code: "gb",
    // },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (code) => {
    setAnchorEl(null);
    i18n.changeLanguage(code).then(() => {
      localStorage.setItem("lang", code);
    });
  };

  return (
    <>
      <Button
        id="languages_btn"
        aria-controls="languages_menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={handleClick}
      >
        <Typography className="d-none d-lg-block" variant="span">Language</Typography>
        <LanguageIcon />
      </Button>
      <Menu
        id="languages_menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "languages_btn",
        }}
      >
        {languages.map(({ code, name, country_code }) => (
          <MenuItem
            id={`languages_item_${country_code}`}
            key={country_code}
            onClick={() => handleClose(code)}
          >
            <span className={`flag-icon flag-icon-${country_code} mx-2`}></span>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Languages;
