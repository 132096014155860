import React from "react";
import "./resume.css";
import { useTranslation } from "react-i18next";

const Resume = () => {
  const { t } = useTranslation("resume");

  return (
    <section id="resume" className="resume">
      <div className="container">
        <div className="section-title">
          <h2>{t("resume_title")}</h2>
          <p>{t("resume_text")}</p>
        </div>

        <div className="row">
          <div className="col-lg-6" data-aos="fade-right">
            {t("left_col").map(
              ({ resume_education, resume_education_list }, index) => (
                <div key={index}>
                  <h3 className="resume-title">{resume_education}</h3>
                  {resume_education_list.map((
                      { resume_school, resume_year, resume_subject },
                      indexOfEducationList
                    ) => (
                      <div
                        key={indexOfEducationList}
                        className="resume-item pb-0"
                      >
                        <h4>{resume_school}</h4>
                        <h5>{resume_year}</h5>
                        {resume_subject.map(
                          ({ title, resume_li }, indexOfSubject) => (
                            <div key={indexOfSubject}>
                              <p>
                                <em>{title ? title : ''}</em>
                              </p>
                              <ul>
                                {resume_li?.map((item, indexOfLi) => (
                                  <li key={indexOfLi}>{item}</li>
                                ))}
                              </ul>
                            </div>
                          )
                        )}
                      </div>
                    )
                  )}
                </div>
              )
            )}

          </div>
          <div className="col-lg-6" data-aos="fade-left" data-aos-delay="100">
            {t("right_col").map(
              ({ resume_education, resume_education_list }, index) => (
                <div key={index}>
                  <h3 className="resume-title">{resume_education}</h3>
                  {resume_education_list.map(
                    (
                      { resume_school, resume_year, resume_subject },
                      indexOfEducationList
                    ) => (
                      <div
                        key={indexOfEducationList}
                        className="resume-item pb-0"
                      >
                        <h4>{resume_school}</h4>
                        <h5>{resume_year}</h5>
                        {resume_subject.map(
                          ({ title, resume_li }, indexOfSubject) => (
                            <div key={indexOfSubject}>
                              <p>
                                <em>{title ? title : ''}</em>
                              </p>
                              <ul>
                                {resume_li?.map((item, indexOfLi) => (
                                  <li key={indexOfLi}>{item}</li>
                                ))}
                              </ul>
                            </div>
                          )
                        )}
                      </div>
                    )
                  )}
                </div>
              )
            )}
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-6" data-aos="fade-right">
            {t("left_col").map(
              ({ resume_education, resume_education_list }, index) => (
                <div key={index}>
                  <h3 className="resume-title">{resume_education}</h3>
                  {resume_education_list.map(
                    (
                      { resume_school, resume_year, resume_subject, resume_li },
                      index
                    ) => (
                      <div key={index} className="resume-item pb-0">
                        <h4>{resume_school}</h4>
                        <h5>{resume_year}</h5>
                        <p>
                          <em>{resume_subject}</em>
                        </p>
                        <ul>
                          {resume_li.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    )
                  )}
                </div>
              )
            )}

          </div>
          <div className="col-lg-6" data-aos="fade-left" data-aos-delay="100">
            {t("right_col").map(
              ({ resume_education, resume_education_list }, index) => (
                <div key={index}>
                  <h3 className="resume-title">{resume_education}</h3>
                  {resume_education_list.map(
                    (
                      { resume_school, resume_year, resume_subject },
                      indexOfEducationList
                    ) => (
                      <div
                        key={indexOfEducationList}
                        className="resume-item pb-0"
                      >
                        <h4>{resume_school}</h4>
                        <h5>{resume_year}</h5>
                        {resume_subject.map(
                          ({ title, resume_li }, indexOfSubject) => (
                            <div key={indexOfSubject}>
                              <p>
                                <em>{title}</em>
                              </p>
                              <ul>
                                {resume_li.map((item, indexOfLi) => (
                                  <li key={indexOfLi}>{item}</li>
                                ))}
                              </ul>
                            </div>
                          )
                        )}
                      </div>
                    )
                  )}
                </div>
              )
            )}
          </div>
        </div> */}


      </div>
    </section>
  );
};

export default Resume;
