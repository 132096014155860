import apiClient from "../../api/apiClient";

const service = () => {
  const createData = (data) => apiClient().post(`/create-admin`, data);
  const updateData = (id, data) => apiClient().put(`/update-admin/${id}`, data);

  return {
    createData,
    updateData,
  };
};

export default service;
