import apiClient from "../../api/apiClient";

const service = () => {
  const readData = () => apiClient().get(`/read-login`);
  const readSingleData = (id) => apiClient().get(`/read-single-login/${id}`);
  const createData = (data) => apiClient().post(`/create-login`, data);

  return {
    readData,
    readSingleData,
    createData,
  };
};

export default service;
