import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField, Button, Alert, Stack } from "@mui/material";
import {
  usernameProps,
  oldPasswordProps,
  passwordProps,
  rePasswordProps,
  submitBtnProps,
} from "./properties";
import { loadUpdateData } from "../../../redux/sections/adminRegister.section/reducers/thunks";

const AccountSetting = () => {
  const adminID = localStorage.getItem("adminID");

  const dispatch = useDispatch();
  const { error, message } = useSelector((state) => state.adminRegister);

  const [data, setData] = useState({
    username: "",
    oldPassword: "",
    password: "",
    rePassword: "",
  });
  const { username, oldPassword, password, rePassword } = data;
  const [errorMsg, setErrorMsg] = useState([]);
  const [resMessage, setResMessage] = useState({});

  useEffect(() => {
    setErrorMsg(error);
  }, [error]);

  useEffect(() => {
    setResMessage(message);
  }, [message]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const submitBtn = (e) => {
    e.preventDefault();

    dispatch(loadUpdateData(adminID, data));
    setData({
      username: "",
      oldPassword: "",
      password: "",
      rePassword: "",
    });
  };

  return (
    <div className="row">
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: "100px 30px 30px",
          position: "relative",
        }}
        noValidate
        autoComplete="off"
        method="POST"
        onSubmit={submitBtn}
      >
        <h4 className="mb-4">Fiók beállítás</h4>
        <Stack sx={{ position: "absolute", top: 15 }}>
          {resMessage && resMessage.error_msg && (
            <Alert severity="error">{resMessage.error_msg}</Alert>
          )}
          {resMessage && resMessage.success_msg && (
            <Alert severity="success">{resMessage.success_msg}</Alert>
          )}
        </Stack>
        <TextField
          value={username}
          onChange={handleChange}
          {...usernameProps}
        />
        <TextField
          value={oldPassword}
          onChange={handleChange}
          {...oldPasswordProps}
        />
        <TextField
          value={password}
          onChange={handleChange}
          {...passwordProps}
        />
        <TextField
          value={rePassword}
          onChange={handleChange}
          {...rePasswordProps}
        />

        <Button className="mt-4" {...submitBtnProps}>
          Módosítás
        </Button>

        <Stack sx={{ mt: 5 }}>
          {errorMsg &&
            errorMsg.map((err, key) => (
              <Alert key={key} severity="error">
                {err.msg}
              </Alert>
            ))}
        </Stack>
      </Box>
    </div>
  );
};

export default AccountSetting;
