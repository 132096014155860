export const usernameProps = {
    variant: "standard",
    label: "felhasználónév",
    name: "username",
};
export const passwordProps = {
    variant: "standard",
    label: "jelszó",
    name: "password",
};
export const submitBtnProps = {
    type: "submit",
    name: "login",
};
