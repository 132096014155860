import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logo } from "../../../config/baseOptions";
import { Button } from "@mui/material";

const Navbar = () => {
    const navigate = useNavigate();

    const signoutBtn = () => {
      localStorage.removeItem("adminID");
      localStorage.removeItem("adminTOKEN");
      navigate("/");
    };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link className="navbar-brand" to={logo.logo_url}>
          {logo.logo}
        </Link>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/admin">
                Kezdőlap
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admin-settings">
                Beállítások
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <Button className="nav-link" onClick={signoutBtn}>
            Kijelentkezés
          </Button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
