import React from 'react'
import AccountSetting from '../../components/accountSetting/AccountSetting';

const AdminSettings = () => {
  return (
    <div className='container'>
      <AccountSetting />
    </div>
  );
}

export default AdminSettings