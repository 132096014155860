export const socials = [
  {
    href: "https://www.linkedin.com/in/gerg%C5%91-h%C3%B6ller-0335b723a",
    icon: "bx bxl-linkedin",
  },
  {
    href: "https://www.github.com/Proggery",
    icon: "bx bxl-github",
  },
  {
    href: "https://www.facebook.com/geri.holler75/",
    icon: "bx bxl-facebook",
  },
];
