import React from "react";
import "./about.css";
import { useTranslation } from "react-i18next";
import { Box, Button, List, ListItem } from "@mui/material";
import { ExternalLink } from "react-external-link";
import { socials } from "../../config/socials";

const About = () => {
  const { t } = useTranslation("about");

  return (
    <section id="about" className="about">
      <div className="container-fluid">
        <div className="container section-title">
          <h2>{t("about_title")}</h2>
          <p>{t("about_text")}</p>

          <Box sx={{ display: "flex" }}>
            <ExternalLink
              style={{ textDecoration: "none", margin: "1rem auto" }}
              href={socials[0].href}
            >
              <Button variant="outlined">{t("about_linkedin")}</Button>
            </ExternalLink>
          </Box>
        </div>

        <div className="row">
          <div className="about_img_frame col-lg-5" data-aos="fade-right">
            <div className="about_img_content">
              <img
                src="/assets/img/about_img.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7 pt-4 pt-lg-0 content" data-aos="fade-left">
            <h3>{t("about_content_title")}</h3>
            <p className="fst-italic">{t("about_content_text")}</p>
            <div className={`row px-3 ${t("about_ul_text") !== "" ? "" : "mt-5 pt-3"}`}>
              <List
                sx={{ display: "grid" }}
              >
                {t("about_li").map(({ key, value }, index) => (
                  <ListItem key={index}>
                    <i className="bi bi-chevron-right"></i>
                    <strong>{key}</strong>
                    <span>{value}</span>
                  </ListItem>
                ))}
              </List>
            </div>
            <p>{t("about_ul_text")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
