export const MetaTitle = (title) => {
  document.title = title;
};

export const globalColors = {
  primary_color: "#0E6E61",
  secondary_color: "#071D3B",
};

// MEDIA QUERY
const size = {
  mobile: '575.98px',
  tablet: '991.98px',
  display: '1999.98px',
};

export const device = {
  mobile: `(max-width: ${size.mobile})`,
 tablet: `(max-width: ${size.tablet})`,
 display: `(max-width: ${size.display})`,
};