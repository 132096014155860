import React, { useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";



// LAYOUT
import FrontLayout from "./layout/FrontLayout";
import AdminLayout from "./layout/AdminLayout";

// PAGES
import HomePage from "./pages/home/Home";
import LoginPage from "./pages/login/Login";
import AdminRegister from "./pages/adminRegister/AdminRegister";
import AdminHomePage from "./admin/pages/adminHome/AdminHome";
import AdminSettingsPage from "./admin/pages/adminSettings/AdminSettings";

const homePage = (
  <FrontLayout>
    <HomePage />
  </FrontLayout>
);
const loginPage = (
  <FrontLayout>
    <LoginPage />
  </FrontLayout>
);
const adminRegisterPage = (
  <FrontLayout>
    <AdminRegister />
  </FrontLayout>
);
const adminHomePage = (
  <AdminLayout>
    <AdminHomePage />
  </AdminLayout>
);
const adminSettingsPage = (
  <AdminLayout>
    <AdminSettingsPage />
  </AdminLayout>
);

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <>
      <HashRouter>
        <Routes>
          <Route exact path="/" element={homePage} />
          <Route path="login" element={loginPage} />
          <Route path="admin-register" element={adminRegisterPage} />

          <Route path="admin" element={adminHomePage} />
          <Route path="admin-settings" element={adminSettingsPage} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;