import React from "react";
import "./hero.css";
import Typewriter from "typewriter-effect";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation(["hero"]);
  const TypewriterData = [
    t("hero_typewriter.tw1"),
    t("hero_typewriter.tw2"),
    t("hero_typewriter.tw3"),
    t("hero_typewriter.tw4"),
    t("hero_typewriter.tw5"),
  ];

  return (
    <section
      id="hero"
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div className="hero-container" data-aos="fade-in">
        <h1> {t("hero_name")}</h1>
        <h2 className="hero_text">
          <Typewriter
            options={{
              strings: [...TypewriterData],
              autoStart: true,
              loop: true,
            }}
          />
        </h2>
      </div>
    </section>
  );
};

export default Hero;
