import React from "react";
import Header from "../admin/components/header/Header";
import { Body, Main } from "../config/css/globalStyle";

const AdminLayout = (props) => {
  return (
    <Body id="front_layout">
      <Header/>
      <Main id="home_page">{props.children}</Main>
     <div>Footer</div>
    </Body>
  );
};

export default AdminLayout;
