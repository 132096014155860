import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LangDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import App from "./App";
import { Provider } from "react-redux";
import configureStore from "./redux/store/configureStore";

import "flag-icon-css/css/flag-icons.min.css";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LangDetector)
  .use(HttpApi)
  .init({
    lng: "de",
    supportedLngs: ["hu", "de", "en"],
    fallbackLng: "de",
    detection: {
      order: [
        "cookie",
        "htmlTag",
        "localStorage",
        "sessionStorage",
        "path",
        "subdomain",
      ],
      caches: ["cookie"],
    },
    ns: ["hero", "about", "skills", "resume", "contact"],
    backend: {
      loadPath: "/assets/languages/{{ns}}/{{lng}}.json",
    },
    returnObjects: true,
    react: {
      useSuspense: true,
    },
  });

const container = document.getElementById("root");
const root = createRoot(container);
const store = configureStore();

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
