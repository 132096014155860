import React, { useEffect } from "react";
import "./navbar.css";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

const Navbar = (props) => {
  const { t } = useTranslation("navbar");

  useEffect(() => {
    const body = document.querySelector("body");
    const link = document.querySelectorAll("a");

    for (let i = 0; i < link.length; i++) {
      const linkItem = link[i];
      linkItem.addEventListener("click", () => {
        body.classList.remove("mobile-nav-active");
        props.setToggleBtn(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav id="navbar" className="nav-menu navbar">
      <ul>
        {t("navbar_li").map(({ icon, to, title }, index) => (
          <li key={index}>
            <Link to={to} className="nav-link scrollto">
              <i className={icon}></i> <span>{title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
