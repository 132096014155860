import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AccountSetting from "../../components/accountSetting/AccountSetting";
import { useDispatch, useSelector } from "react-redux";
import { loadReadSingleData } from "../../../redux/sections/login.section/reducers/thunks";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AdminHome = () => {
  const adminID = localStorage.getItem("adminID");

  const dispatch = useDispatch();
  const { readSingleData } = useSelector((state) => state.login);

  const [adminUser, setAdminUser] = useState("");
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(true);

  useEffect(() => {
    dispatch(loadReadSingleData(adminID));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (readSingleData && readSingleData.username !== "admin") {
      setOpen(false);
    }
    setAdminUser(readSingleData && readSingleData.username);
  }, [readSingleData]);
  return (
    <>
      {adminUser && adminUser === "admin" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <AccountSetting />
          </Box>
        </Modal>
      ) : (
        <div>már nem admin</div>
      )}
    </>
  );
};

export default AdminHome;
