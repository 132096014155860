import { combineReducers } from "redux";
import adminRegisterReducer from "../sections/adminRegister.section/reducers/reducer";
import loginReducer from "../sections/login.section/reducers/reducer";

const state = () =>
  combineReducers({
    adminRegister: adminRegisterReducer,
    login: loginReducer,
  });

export default state;
