import React from "react";
import "./skills.css";
import SkillBar from "react-skillbars";
import { globalColors } from "../../config/globalFunctions";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const skills = [
  { type: "Html5", level: 80 },
  { type: "CSS", level: 82 },
  { type: "SASS", level: 42 },
  { type: "PHP", level: 45 },
  { type: "Javascript", level: 50 },
  { type: "Typescript", level: 5 },
  { type: "Composer", level: 10 },
  { type: "Docker", level: 5 },
  { type: "React", level: 43 },
  { type: "Redux", level: 14 },
  { type: "Bootstrap", level: 80 },
  { type: "Material UI", level: 46 },
  { type: "Photoshop", level: 28 },
  { type: "Node js", level: 5 },
  { type: "Ejs", level: 5 },
  { type: "MySQL", level: 26 },
  { type: "Phpmyadmin", level: 55 },
  { type: "Git", level: 36 },
  { type: "Figma", level: 48 },
  { type: "Wordpress", level: 40 },
  { type: "Laravel-Blade", level: 80 },
];
const colors = {
  bar: {
    hue: {
      minimum: 190,
      maximum: 280,
    },
    saturation: 50,
    level: {
      minimum: 30,
      maximum: 90,
    },
  },
  title: {
    background: globalColors.primary_color,
  },
};

const Skills = () => {
  const { t } = useTranslation("skills");

  useEffect(() => {
    const skillElement = document.querySelectorAll(".skillbar");
    const skillsContent = document.querySelector(".skills-content div");

    skillsContent.classList.add("d-lg-flex");

    let skillLength = Math.floor(skillElement.length / 2);
    let skillArr1 = [...skillElement].slice(0, skillLength);
    let skillArr2 = [...skillElement].slice(skillLength, skillElement.length);

    let skillDiv = document.createElement("div");
    for (const element of skillArr1) {
      skillDiv.appendChild(element);
    }

    let nextSkillDiv = document.createElement("div");
    for (const element of skillArr2) {
      nextSkillDiv.appendChild(element);
    }

    skillsContent.appendChild(skillDiv);
    skillsContent.appendChild(nextSkillDiv);

    for (const element of skillsContent.children) {
      element.classList.add("col-lg-6");
      skillsContent.children[0].classList.add("pe-4");
    }
  }, []);

  return (
    <section id="skills" className="skills section-bg">
      <div className="container">
        <div className="section-title">
          <div className="d-flex">
            <h2>{t("skills_title")}</h2>
            <h4 className="ms-3" style={{ lineHeight: 1.7 }}>
              {t("skills_subtitle")}
            </h4>
          </div>
          <p>{t("skills_text")}</p>
        </div>

        <div className="row skills-content">
          <SkillBar offset="400px" skills={skills} colors={colors} height={25} />
        </div>
      </div>
    </section>
  );
};

export default Skills;
