import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PopupModal from "../popupModal/PopupModal";
import CardFlip from "./CardFlip";
import "./portfolio.css";
import { PortfolioContainer } from "./portfolio.styles";
import datas from "./projects.json";


const Portfolio = () => {
  const { t } = useTranslation("portfolio");
  const projects = t("portfolio_projects_back", {
    returnObjects: true,
  });

  const [projectId, setProjectId] = useState(0);

  // Cards back info
  let projectObj = {};

  projects[projectId].map((item, index) => {
    return (projectObj[`item_${index}`] = item);
  });

  // Flip remove
  const removeClick = (id) => {
    const elementFront = document.querySelectorAll(".react-card-front");
    const elementBack = document.querySelectorAll(".react-card-back");

    for (let i = 0; i < elementFront.length; i++) {
      const e = elementFront[i];
      if (i === id) {
        e.classList.remove("addFlipFront");
        e.classList.remove("removeFlip");
      }
    }

    for (let i = 0; i < elementBack.length; i++) {
      const e = elementBack[i];
      if (i === id) {
        e.classList.remove("addFlipBack");
        e.classList.remove("removeFlip");
      }
    }
  };

  return (
    <section id="portfolio" className="portfolio section-bg">
      <div className="container">
        <div className="section-title">
          <h2>{t("portfolio_title")}</h2>
        </div>

        <div className="row" data-aos="fade-up">
          <div className="col-lg-12 d-flex justify-content-center">
            {/* <ul id="portfolio-flters">
              <li data-filter="*" className="filter-active">
                Összes
              </li>
              <li data-filter=".filter-app">App</li>
              <li data-filter=".filter-card">Card</li>
              <li data-filter=".filter-web">Web</li>
            </ul> */}
          </div>
        </div>

        <div
          className="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <PortfolioContainer>
            {datas.map((data, index) => (
              <CardFlip
                key={index}
                data={data}
                projects={projects}
                id={index}
                setProjectId={setProjectId}
                removeClick={removeClick}
              />
            ))}
          </PortfolioContainer>
        </div>
      </div>
      <PopupModal
        removeClick={removeClick}
        projectId={projectId}
        title={projectObj.item_0}
        text={projectObj.item_4}
      />
    </section>
  );
};

export default Portfolio;
