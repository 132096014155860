import React from "react";
import "./toggleBtn.css";
import { IconButton } from "@mui/material";

const ToggleBtn = (props) => {
  const handleToggle = () => {
    let body = document.querySelector("body");
    body.classList.toggle("mobile-nav-active");
    props.setToggleBtn(!props.toggleBtn);
  };
  return (
    <>
      <IconButton className="d-xl-none" sx={{ p: 0 }} aria-label="menu" onClick={handleToggle}>
        <div className={`toggle_btn ${props.toggleBtn ? "active" : null}`}>
          <span className="toggle_line"></span>
          <span className="toggle_line"></span>
          <span className="toggle_line"></span>
        </div>
      </IconButton>
    </>
  );
};

export default ToggleBtn;
