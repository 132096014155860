//? Meta Title
export const metaTitle = {
    home: "Kezdőlap",
    about: "Rólunk",
    services: "Szolgáltatások",
    contact: "Kapcsolat",
}

//? Logo
export const logo = {
    logo: "Crazydesign",
    logo_url: "/"
}