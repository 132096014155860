import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation(["hero"]);

  let today = new Date();
  const date = today.getFullYear()

  return (
    <footer id="footer" className="container">
      <div className="container">
        <div className="copyright">
          {date} &copy; Copyright
          <strong>
            <span> {t("hero_name")}</span>
          </strong>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
