import { InfoOutlined } from "@mui/icons-material";
import styled from "styled-components";
import { Colors } from "../../config/css/globalStyle";
import { device } from "../../config/globalFunctions";

export const PortfolioContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

// };

export const ProjectList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  height: 100%;

  li {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 1px 0 #9b9c9f;
    color: ${Colors.blue};

    :last-child {
      border-bottom: none;
      font-size: 0.8rem;
      color: ${Colors.green};
    }
  }
`;
export const InfoOutlinedIcon = styled(InfoOutlined)`
  position: absolute;
  right: -30px;
  cursor: pointer;
  z-index: 4;
`;
