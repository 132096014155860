import * as actionType from "./actionTypes";

const readData = (readData) => ({
  type: actionType.READ_DATA,
  payload: readData,
});
const readSingleData = (readSingleData) => ({
  type: actionType.READ_SINGLE_DATA,
  payload: readSingleData,
});
const createData = () => ({
  type: actionType.CREATE_DATA,
});
const error = (error) => ({
  type: actionType.ERROR,
  payload: error,
});
const message = (message) => ({
  type: actionType.MESSAGE,
  payload: message,
});

const actions = {
  readData,
  readSingleData,
  createData,
  error,
  message,
};

export default actions;
