import * as actionType from "./actionTypes";

const readSingleData = (readSingleData) => ({
  type: actionType.READ_SINGLE_DATA,
  payload: readSingleData,
});

const createAdmin = () => ({
  type: actionType.CREATE_DATA,
});

const updateAdmin = () => ({
  type: actionType.UPDATE_DATA,
});

const error = (error) => ({
  type: actionType.ERROR,
  payload: error,
});
const message = (message) => ({
  type: actionType.MESSAGE,
  payload: message,
});

const actions = {
  readSingleData,
  createAdmin,
  updateAdmin,
  error,
  message,
};

export default actions;
