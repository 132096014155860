const initialState = () => {
  return {
    readData: {},
    readSingleData: {},
    error: [],
    message: {},
  };
};

export default initialState;
